import { Select, TextInput } from 'Components/Form'

export default function BulkoBagMaterial({ names, index, data, setFieldValue, touched, errors, bulko_bags, batchRejectTypes }) {
    var name = `${names}batch_bulko_bag_material[${index}].`

    var bulkoBag = data.waste_drop_material_type

    return (
        <div className="pt-3 border-t border-stroke">
            <label className="block mb-1 font-medium text-black dark:text-white">{data.material.title}</label>
            <div className="grid grid-cols-2 gap-3 md:grid-cols-6">
                <TextInput
                    type="number"
                    id={`${name}accept_bags`}
                    name={`${name}accept_bags`}
                    label="No of Bags Accepted"
                    placeholder="No of Bags Accepted"
                    value={data.accept_bags}
                    error={touched?.accept_bags && errors?.accept_bags}
                    min="0"
                    max={bulkoBag?.accept_bags ?? 0}
                    onChange={e => {
                        var reject_bags = 0
                        if (bulkoBag) {
                            reject_bags = (bulkoBag.accept_bags ?? 0) - e.target.value
                        }
                        setFieldValue(`${name}accept_bags`, e.target.value)
                        setFieldValue(`${name}reject_bags`, reject_bags)
                    }}
                />
                <TextInput
                    type="number"
                    id={`${name}accept_weight`}
                    name={`${name}accept_weight`}
                    label="Accepted Bags Kg"
                    placeholder="Accepted Bags Kg"
                    value={data.accept_weight}
                    error={touched?.accept_weight && errors?.accept_weight}
                    min="0"
                    max={bulkoBag?.accept_weight ?? 0}
                    icon="KG"
                    onChange={e => {
                        var reject_weight = 0
                        if (bulkoBag) {
                            reject_weight = (bulkoBag.accept_weight ?? 0) - e.target.value
                        }
                        setFieldValue(`${name}accept_weight`, e.target.value)
                        setFieldValue(`${name}reject_weight`, reject_weight)
                    }}
                />
                <TextInput
                    type="number"
                    id={`${name}reject_bags`}
                    name={`${name}reject_bags`}
                    label="No of Bags Rejected"
                    placeholder="No of Bags Rejected"
                    value={data.reject_bags}
                    error={touched?.reject_bags && errors?.reject_bags}
                    min="1"
                    disabled
                />
                <TextInput
                    type="number"
                    id={`${name}reject_weight`}
                    name={`${name}reject_weight`}
                    label="Rejected Bags kg"
                    placeholder="Rejected Bags kg"
                    value={data.reject_weight}
                    error={touched?.reject_weight && errors?.reject_weight}
                    min="1"
                    icon="KG"
                    disabled
                />
                <Select
                    id={`${name}batch_reject_type_id`}
                    name={`${name}batch_reject_type_id`}
                    label="Batch Reject Type"
                    placeholder="Batch Reject Type"
                    value={data.batch_reject_type_id}
                    options={batchRejectTypes.map(e => ({
                        id: e.id,
                        label: e.name,
                    }))}
                    onChange={e => {
                        setFieldValue(`${name}batch_reject_type_id`, e.target.value)
                    }}
                    error={touched?.batch_reject_type_id && errors?.batch_reject_type_id}
                    disabled={!((data.reject_bags ?? 0) > 0 || (data.reject_weight ?? 0) > 0)}
                />
                {data.batch_reject_type_id === 'others' ? (
                    <TextInput
                        id={`${name}reject_type`}
                        name={`${name}reject_type`}
                        label="Reject Type"
                        placeholder="Reject Type"
                        value={data.reject_type}
                        error={touched?.reject_type && errors?.reject_type}
                        disabled={!((data.reject_bags ?? 0) > 0 || (data.reject_weight ?? 0) > 0)}
                    />
                ) : null}
            </div>
        </div>
    )
}
